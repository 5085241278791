import Cart from "@/components/Cart/Cart";


const CartPage = () => {
  return (
    <div>
      <Cart/>
    </div>
  );
};

export default CartPage;
