import Status from "@/Status/Status"

const StatusPage = () => {
  return (
    <div>
      <Status/>
    </div>
  )
}

export default StatusPage