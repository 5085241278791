import Delivery from "@/components/Delivery/Delivery"


const DeliveryPage = () => {
  return (
    <div>
        <Delivery/>
    </div>
  )
}

export default DeliveryPage