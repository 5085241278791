import CatalogLayout from '@/components/Layout/ui/CatalogLayout'

const Catalog = () => {
  return (
    <div>
        <CatalogLayout/>
    </div>
  )
}

export default Catalog